import React, { FC, memo, useCallback } from 'react'
import { OverlayTrigger, Tooltip as BTooltip } from 'react-bootstrap'
import { TooltipProps } from './index.type'
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay'

const Tooltip: FC<TooltipProps> = (props) => {
  const { id, message, children, show, placement = 'auto', delay = 250, className } = props

  const renderTooltip = useCallback(
    (props: OverlayInjectedProps) => (
      <BTooltip id={id} {...props}>
        <span className='d-flex align-items-start text-start'>{message}</span>
      </BTooltip>
    ),
    [message]
  )

  return (
    <OverlayTrigger show={show} placement={placement} overlay={renderTooltip} delay={delay}>
      <div className={className}>{children}</div>
    </OverlayTrigger>
  )
}

export default memo(Tooltip)
