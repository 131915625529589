import useNetworkStatus from 'hooks/use-network-status'
import { useStores } from 'models'
import React, { FunctionComponent } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

type PrivateRouteProps = {
  component: React.ComponentType
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ component: RouteComponent }) => {
  const location = useLocation()

  const {
    authStore: { isAuthenticated },
  } = useStores()

  const { isOnline } = useNetworkStatus()

  /**
   * not logged in so redirect to login page with the return url
   */
  if (!isAuthenticated) return <Navigate to={'/account/login'} state={{ from: location }} replace />

  if (!isOnline) return <Navigate to={'/no-internet-connection'} replace />

  // add if route is restricted by role below here

  return <RouteComponent />
}

export default PrivateRoute
