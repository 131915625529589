import { flexRender } from '@tanstack/react-table'
import { startCase } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { isEmpty, removeEmptyProperties } from 'utils/object'
import { useError } from 'hooks/use-error.hook'

import { TableEmpty } from './table-error'
import { TableBodyProps } from './table.types'

export const TableBody = (props: TableBodyProps) => {
  const { table, sorting, filtering, pagination, onFetchData, setIsLoading, ...tableEmptyProps } =
    props

  const { t } = useTranslation()
  const { handleGlobalError } = useError()
  const { pathname, search } = useLocation()

  const triggerDataFetch = async () => {
    const fetchOptions = Object.create({})
    const { search: searchFilter, ...allFilters } = filtering

    const filters = removeEmptyProperties(allFilters)
    const sort = sorting.reduce((result, { id, desc }) => {
      result[id] = desc ? 'desc' : 'asc'
      return result
    }, Object.create({}))

    try {
      setIsLoading(true)
      if (searchFilter) fetchOptions.search = searchFilter
      if (!isEmpty(filters)) fetchOptions.filters = filters
      if (!isEmpty(sort)) fetchOptions.sorts = sort
      if (pagination.pageIndex) fetchOptions.page = pagination.pageIndex + 1
      if (pagination.pageSize) fetchOptions.limit = pagination.pageSize

      await onFetchData(fetchOptions)
    } catch (error) {
      handleGlobalError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    triggerDataFetch()
  }, [pathname, search])

  if (!table.getRowModel().rows.length) {
    return <TableEmpty {...tableEmptyProps} table={table} />
  }

  return (
    <tbody className='table-body'>
      {table.getRowModel().rows.map((row) => (
        <tr key={row.id} className='border border-neutral-2 rounded'>
          {row.getVisibleCells().map((cell) => (
            <td
              className={`align-middle ${cell.column.id !== 'category' ? 'rounded' : ''}`}
              key={cell.id}
              data-label={t(startCase(cell.column.id))}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}
