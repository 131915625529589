import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { useStores } from 'models'
import { ApiErrorKind } from 'services/api'
import { useProfile } from 'models/hooks/use-profile'
import { loginScheme } from './scheme'

export type LoginData = {
  email: string
  password: string
}

export default function useLogin() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    authStore: { login, isAuthenticated, registrationCompletion },
  } = useStores()

  const { handleGetProfile } = useProfile()

  const [isLoading, setIsLoading] = useState(false)

  const [error, setError] = useState(null)

  const loginResolver = yupResolver(loginScheme(t))

  const navigateToDashboard = () => {
    navigate('/events', { replace: true })
  }

  const navigateToVerify = () => {
    navigate('/verification-email', { replace: true })
  }

  const navigateWelcome = () => {
    navigate('/welcome-organizer', { replace: true })
  }

  const doLogin = async ({ email, password }: LoginData) => {
    try {
      setIsLoading(true)
      setError(null)
      const submit = await login(email, password)

      if (submit && isAuthenticated) {
        return navigateToDashboard()
      } else if (submit && registrationCompletion?.lastStep === 1) {
        return navigateToVerify()
      } else if (submit && registrationCompletion?.lastStep === 2) {
        return navigateWelcome()
      }
    } catch (error) {
      if (
        error.kind === ApiErrorKind.UNPROCESSABLE ||
        error.kind === ApiErrorKind.NOT_FOUND ||
        error.kind === ApiErrorKind.UNAUTHORIZED ||
        error.kind === ApiErrorKind.BAD_REQUEST
      ) {
        setError(t('Email or password is invalid!'))
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      handleGetProfile()
      navigateToDashboard()
    }
  }, [isAuthenticated])

  return { isLoading, loginResolver, doLogin, error }
}
