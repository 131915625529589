import { useStores } from 'models'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useCustomNavigate = () => {
  const navigate = useNavigate()

  const {
    challengeStore: { setHasUnsavedChanges, setPendingNavigation },
  } = useStores()

  const customNavigate = useCallback(
    (to: string, blockPathName: string, currentPathName: string) => {
      if (to !== blockPathName && currentPathName === blockPathName) {
        setHasUnsavedChanges(true)
        setPendingNavigation(to)
      } else {
        navigate(to, { replace: true })
      }
    },
    []
  )

  return {
    customNavigate,
  }
}
