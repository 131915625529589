import React, { FC, memo } from 'react'
import { TabMenusProps } from './index.types'
import TabMenu from '../tab-menu'
import { Accordion } from 'react-bootstrap'

const TabMenus: FC<TabMenusProps> = (props) => {
  const { items, numberTab, handleClick, customTabItem: CustomTabItem, changeTab } = props

  return (
    <Accordion>
      {items
        .filter((item) => !item?.hidden)
        .map((item, index) => {
          return item?.isParent ? (
            <CustomTabItem
              key={index}
              {...item}
              isActive={numberTab === index}
              index={index}
              handleClick={handleClick}
              changeTab={changeTab}
            />
          ) : (
            <TabMenu
              key={index}
              {...item}
              isActive={numberTab === index}
              index={index}
              handleClick={handleClick}
              changeTab={changeTab}
            />
          )
        })}
    </Accordion>
  )
}

export default memo(TabMenus)
