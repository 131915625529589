import React, { FunctionComponent } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { SubMenus } from './types'
import { useCustomNavigate } from 'hooks/use-custom-navigate.hook'
import { challengesEditPath } from 'config/paths'

const MenuItemLink: FunctionComponent<SubMenus> = (props) => {
  const { item, className } = props

  const { customNavigate } = useCustomNavigate()

  const { id } = useParams()
  const { pathname } = useLocation()

  return (
    <span
      onClick={() => {
        customNavigate(item.url, challengesEditPath(id), pathname)
      }}
      className={classNames('side-nav-link-ref', 'side-sub-nav-link', className)}
      data-menu-key={item.key}
    >
      <div className='active-bar'> </div>
      {item.icon && <i className={item.icon}></i>}
      {item.badge && (
        <span
          className={classNames(
            'badge',
            'bg-' + item.badge.variant,
            'rounded',
            'font-10',
            'float-end',
            {
              'text-dark': item.badge.variant === 'light',
              'text-light': item.badge.variant === 'dark' || item.badge.variant === 'secondary',
            }
          )}
        >
          {item.badge.text}
        </span>
      )}
      <span className='fw-semibold menu-item-link-label'> {item.label} </span>
    </span>
  )
}

export default MenuItemLink
