import React from 'react'
import { Card } from 'react-bootstrap'
import classNames from 'classnames'
import Icon from 'components/icon'
import 'tippy.js/dist/tippy.css'
import Tooltip from 'components/tooltip'

type StatisticsWidgetProps = {
  textClass?: string
  bgClass?: string
  icon?: string
  title: string
  stats?: number | string
  type?: 'organizer' | 'event'
  tipTool?: string
}

const StatisticsWidget = ({
  textClass,
  bgClass,
  icon,
  title,
  stats,
  type = 'event',
  tipTool,
}: StatisticsWidgetProps) => {
  return (
    <Card className={classNames('widget-flat', bgClass)}>
      <Card.Body className='widget-color'>
        {icon && (
          <div className='float-end'>
            <i className={classNames(icon, 'widget-icon')}></i>
          </div>
        )}
        {type === 'event' && (
          <div className='d-flex flex-column'>
            <h1 className={classNames('my-2 text-black', textClass || null)}>{stats}</h1>
            <h4 className={classNames('fw-normal', 'mb-1', textClass || 'text-black')}>{title}</h4>
          </div>
        )}
        {type === 'organizer' && (
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between align-items-start'>
              <text className={classNames('mb-3 text-card-title', textClass)}>{title}</text>
              {tipTool && (
                <Tooltip message={tipTool}>
                  <Icon icon='icon-info-circle1' className='text-black' size={20} />
                </Tooltip>
              )}
            </div>
            <text className={classNames('text-card-subtitle-2', textClass)}>{stats}</text>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default StatisticsWidget
