import {
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useSearchParams } from 'react-router-dom'

import { Pagination } from './pagination'
import { TableBody } from './table-body'
import { TableError } from './table-error'
import { TableHeader } from './table-header'
import {
  getFilterFromSearchParams,
  getFilterParams,
  getPaginationFromSearchParams,
  getSortFromSearchParams,
  getSortParams,
} from './table.helper'
import { FilterState, TableProps } from './table.types'

export const Table = <TableValues extends Record<string, any>>(props: TableProps<TableValues>) => {
  const {
    columns,
    data,
    onFetchData,
    showTableHeader = true,
    totalPage = 1,
    tableClassName,
    sortable = true,
    emptyMessage,
    ...headerProps
  } = props

  const [isLoading, setIsLoading] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const filtering = useMemo(() => getFilterFromSearchParams(searchParams), [searchParams])

  const sorting = useMemo(() => getSortFromSearchParams(searchParams), [searchParams])
  const pagination = useMemo(() => getPaginationFromSearchParams(searchParams), [searchParams])

  const setFiltering = (filters: FilterState) => {
    const params = getFilterParams(searchParams)

    Object.entries(filters).forEach(([key, value]) => {
      if (key !== 'search' && value) {
        params[`filter[${key}]`] = value
      }
    }, Object.create({}))

    params.search = filters.search

    if (!filters.search) delete params.search

    params.page = '1'

    setSearchParams(params)
  }

  const setSorting: any = (updater: (old: SortingState) => SortingState) => {
    const params = getSortParams(searchParams)

    updater(sorting).forEach(({ id, desc }) => {
      params[`sort[${id}]`] = desc ? 'desc' : 'asc'
    }, Object.create({}))

    setSearchParams(params)
  }

  const setPagination: any = (updater: (old: PaginationState) => PaginationState) => {
    const params = Object.fromEntries(searchParams)
    const { pageIndex, pageSize } = updater(pagination)

    params.page = `${pageIndex + 1}`
    params.perPage = `${pageSize}`

    setSearchParams(params)
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    manualPagination: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    pageCount: totalPage,
  })

  return (
    <div className='bg-transparent'>
      {showTableHeader && (
        <div className='mb-3'>
          <TableHeader {...headerProps} onFilter={setFiltering} />
        </div>
      )}
      <div className={classNames(tableClassName, 'table-wrapper pb-1')}>
        <table className='table advanced-table rounded table-nowrap'>
          <thead className='table border-neutral-2 bg-neutral-2 rounded'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const canSort = sortable && header.column.getCanSort()
                  return (
                    <th key={header.id} className='py-0 px-0'>
                      {!header.isPlaceholder && (
                        <div
                          className={classNames('table-th fw-bold', {
                            sortable: canSort,
                          })}
                          onClick={canSort ? header.column.getToggleSortingHandler() : undefined}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {canSort && (
                            <i
                              className={classNames({
                                'uil-direction': !header.column.getIsSorted(),
                                'uil-angle-down': header.column.getIsSorted() === 'desc',
                                'uil-angle-up': header.column.getIsSorted() === 'asc',
                              })}
                            />
                          )}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <ErrorBoundary FallbackComponent={(props) => <TableError {...props} table={table} />}>
            {useMemo(
              () => (
                <>
                  {data && (
                    <TableBody
                      setIsLoading={setIsLoading}
                      table={table}
                      sorting={sorting}
                      filtering={filtering}
                      pagination={pagination}
                      onFetchData={onFetchData}
                      newLabel={headerProps.newLabel}
                      newPath={headerProps.newPath}
                      message={emptyMessage}
                    />
                  )}
                </>
              ),
              [table, data, sorting, filtering, pagination, onFetchData]
            )}
          </ErrorBoundary>
        </table>
      </div>
      {useMemo(
        () => (
          <>
            {!!data.length && !isLoading && (
              <Pagination
                table={table}
                currentPage={pagination.pageIndex + 1}
                perPage={pagination.pageSize}
                totalPage={totalPage}
              />
            )}
          </>
        ),
        [data?.length, pagination, totalPage]
      )}
    </div>
  )
}
