import React, { FunctionComponent, memo, useCallback, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Dropdown } from 'react-bootstrap'

import { useToggle } from 'hooks'
import { ProfileOption } from './types'
import Icon from 'components/icon'
import { defaultProfile } from 'assets/images'
import { RoundedImage } from 'components/rounded-image/rounded-image'
import classNames from 'classnames'
import { ConfirmationModal } from 'components/confirmation-modal/confirmation-modal'
import { useTranslation } from 'react-i18next'
import { useProfile } from 'models/hooks/use-profile'
import { useStores } from 'models'
import { startCase, toLower } from 'lodash'
import { useCustomNavigate } from 'hooks/use-custom-navigate.hook'
import { challengesEditPath } from 'config/paths'

type ProfileDropdownProps = {
  menuItems: Array<ProfileOption>
  userImage: string
  username: string
  userTitle?: string
}

const ProfileDropdown: FunctionComponent<ProfileDropdownProps> = (props) => {
  const { menuItems, userImage, username, userTitle } = props

  const { t } = useTranslation()

  const navigate = useNavigate()
  const { id } = useParams()
  const { pathname } = useLocation()

  const { reset } = useStores()

  const { isLoading, handleDeleteProfile } = useProfile()

  const { customNavigate } = useCustomNavigate()

  const [isOpen, toggleDropdown] = useToggle()

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const handleShowDeleteModal = useCallback(() => setShowDeleteModal(true), [])
  const handleHideDeleteModal = useCallback(() => setShowDeleteModal(false), [])

  const handleLogout = async () => {
    sessionStorage.clear()
    reset()
    navigate('/account/login', { replace: true })
  }

  const onConfirmDelete = async () => {
    const ok = await handleDeleteProfile()
    if (ok) handleLogout()
    handleHideDeleteModal()
  }

  return (
    <>
      <Dropdown show={isOpen} onToggle={toggleDropdown}>
        <Dropdown.Toggle
          variant='link'
          id='dropdown-profile'
          as={Button}
          className='nav-link dropdown-toggle nav-user arrow-none me-n2'
        >
          <span className='account-user-avatar'>
            <RoundedImage
              image={userImage ?? defaultProfile}
              alt='An awesome user avatar profile'
            />
          </span>
          <span>
            <span className='account-user-name pe-2'>{username}</span>
            <span className='account-position'>{startCase(toLower(userTitle))}</span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          align='end'
          className='dropdown-menu-animated topbar-dropdown-menu profile-dropdown'
        >
          <div className='d-flex flex-column m-1 gap-1' onClick={toggleDropdown}>
            {menuItems.map((item, index) => {
              return item.redirectTo ? (
                <span
                  onClick={() => {
                    customNavigate(item.redirectTo, challengesEditPath(id), pathname)
                  }}
                  className={classNames(
                    'dropdown-item notify-item clickable',
                    item.isDanger && 'text-danger'
                  )}
                  key={index}
                >
                  <Icon icon={item.icon} size={20} />
                  <span>{item.label}</span>
                </span>
              ) : (
                <button
                  onClick={handleShowDeleteModal}
                  className={classNames(
                    'border-0 outline-0 dropdown-item notify-item',
                    item.isDanger && 'text-danger'
                  )}
                  key={index}
                >
                  <Icon icon={item.icon} size={20} />
                  <span>{item.label}</span>
                </button>
              )
            })}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {showDeleteModal && (
        <ConfirmationModal
          visible={showDeleteModal}
          onConfirm={onConfirmDelete}
          onCancel={handleHideDeleteModal}
          title={t('Delete Account')}
          body={t('Are you sure want to delete your account?')}
          variant='primary'
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export default memo(ProfileDropdown)
