import React, { lazy, useMemo } from 'react'

import AdminRoutes from './admin-routes'
import SuperAdminRoutes from './super-admin-routes'
import PrivateRoute from './private-route'
import Layout from 'components/layout/layout'
import AuthRoutes from './shared/auth-routes'
import AuthLayout from 'components/layout/auth-layout'
import WelcomeLayout from 'components/layout/verification-layout'
import RedirectRoute from './redirect-route'
import { useRoutes } from 'react-router-dom'
import ErrorRoutes from './shared/error-routes'
import { useStores } from 'models'
import { eventDetailPath } from 'config/paths/event-path'
import { LoadComponent } from 'routes/load-component'
import WelcomeRoutes from './shared/welcome-routes'
const event = lazy(() => import('pages/creator/event'))

const Routes = () => {
  const {
    profileStore: {
      profile: { isSuperAdmin },
    },
  } = useStores()

  const dynamicRoutes = useMemo(
    () => (isSuperAdmin ? SuperAdminRoutes : AdminRoutes),
    [isSuperAdmin]
  )

  return useRoutes([
    {
      path: '/',
      element: <RedirectRoute route='/' redirectTo='/events' />,
    },
    {
      path: '/',
      element: <AuthLayout />,
      children: AuthRoutes,
    },
    {
      path: '/',
      element: <WelcomeLayout />,
      children: WelcomeRoutes,
    },
    {
      path: '/',
      element: <PrivateRoute component={Layout} />,
      children: dynamicRoutes,
    },
    {
      path: '/',
      children: ErrorRoutes,
    },
    {
      path: eventDetailPath(':id'),
      element: <LoadComponent component={event} />,
    },
  ])
}

export default Routes
