import {
  challengesEditPath,
  challengesEditPreviewPath,
  challengesFormPath,
  challengesPath,
  challengesPreviewPath,
  userProfilePath,
  userSettingsPath,
} from 'config/paths'
import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { LoadComponent } from 'routes/load-component'

const profile = lazy(() => import('pages/shared/profile'))
const settings = lazy(() => import('pages/shared/settings/settings'))

const challenges = lazy(() => import('pages/shared/challenge/list-challenge/challenge'))
const challengeForm = lazy(() => import('pages/shared/challenge/add-challenge/form-challenge'))
const challengeOverview = lazy(() => import('pages/shared/challenge/overview'))
const challengePreview = lazy(() => import('pages/shared/challenge/preview'))

export const challengesRoutes = (): RouteObject[] => [
  {
    index: true,
    path: challengesPath(),
    element: <LoadComponent component={challenges} />,
  },
  {
    path: challengesPath(':id'),
    element: <LoadComponent component={challengeOverview} />,
  },
  {
    path: challengesPreviewPath(),
    element: <LoadComponent component={challengePreview} />,
  },
  {
    path: challengesEditPath(':id'),
    element: <LoadComponent component={challengeForm} />,
  },
  {
    path: challengesEditPreviewPath(':id'),
    element: <LoadComponent component={challengePreview} />,
  },
  {
    path: challengesFormPath(),
    element: <LoadComponent component={challengeForm} />,
  },
]

const userRoutes = (): RouteObject[] => [
  {
    path: userProfilePath(),
    element: <LoadComponent component={profile} />,
  },
  {
    path: userSettingsPath(),
    element: <LoadComponent component={settings} />,
  },
]

export { userRoutes }
