import { emptyState } from 'assets/images'
import Image from 'components/image'
import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyDataProps } from './index.types'

const EmptyData: FC<EmptyDataProps> = (props) => {
  const {
    title = 'No Data We Found',
    description = 'Create your task and share your event to the world.',
  } = props

  const { t } = useTranslation()

  return (
    <div className='d-flex align-items-center justify-content-center flex-column gap-2 py-3'>
      <div>
        <Image image={emptyState} width={170} />
      </div>
      <div className='text-center text-black'>
        <h4 className='m-0'>{t(title)}</h4>
      </div>
      <p className='text-center text-muted'>{t(description)}</p>
    </div>
  )
}

export default memo(EmptyData)
