import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

const ScrollToTopHandler = (): null => {
  const { pathname } = useLocation()

  const { params } = useParams()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [pathname, params])

  return null
}

export default ScrollToTopHandler
