import { contentManagementPath } from 'config/paths/content-management-path'
import { MenuItemType } from './menu.types'

export const SUPER_ADMIN_MENU_ITEMS: MenuItemType[] = [
  {
    key: 'navigation',
    label: 'Navigation',
    isTitle: true,
  },
  {
    key: 'challenge',
    label: 'Events',
    url: '/events',
    icon: 'icon-medal',
  },
  {
    key: 'user',
    label: 'Users Management',
    url: '/user/list',
    icon: 'icon-people',
  },
  {
    key: 'events',
    label: 'Events Management',
    url: '/event/list',
    icon: 'mdi mdi-ticket-confirmation',
  },
  {
    key: 'reporting',
    label: 'Analytics and Reporting',
    url: '/admin/reporting',
    icon: 'mdi mdi-chart-line',
  },
  {
    key: 'financial',
    label: 'Financial Management',
    url: '/admin/financial',
    icon: 'uil uil-money-bill',
  },
  {
    key: 'content-management',
    label: 'Content Management',
    url: contentManagementPath(),
    icon: 'icon-document-content',
  },
]
