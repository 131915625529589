/* eslint-disable prefer-const */
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { App } from './app'

import ScrollToTopHandler from './handlers/scroll-to-top-handler'

const root = createRoot(document.getElementById('app'))
root.render(
  <BrowserRouter>
    <ScrollToTopHandler />
    <App />
  </BrowserRouter>
)
